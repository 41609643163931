@import url(https://fonts.googleapis.com/css?family=Poppins:400,700|Roboto|Roboto+Mono&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

* {
  font-family: "Poppins";
}

body {
  overflow-x: hidden;
}

body,
html {
  height: 100%;
}

.nav-item .nav-link,
.dropdown-toggle {
  font-weight: 700;
  /* color: rgb(6, 41, 88); */
  background-color: transparent;
  border: none;
  padding: 0.85rem;
  transition: color 0.1s ease-in-out;
  color: black !important;
  text-transform: uppercase;
}

.nav-item .active {
  border-top: 1px solid #dee2e6;
  border-right: 1px solid #dee2e6;
  border-left: 1px solid #dee2e6;
  background-color: white;
}

.nav-item .non-active {
  background-color: transparent;
  border: none;
}

.nav-link:hover {
  color: rgb(6, 41, 88) !important;
}

.dropdown-toggle {
  background: none;
  color: grey;
}

.dropdown-toggle:hover,
.dropdown-toggle:focus,
.dropdown-toggle:active {
  background: none !important;
  color: rgb(6, 41, 88) !important;
}

.dropdown-item:hover {
  background-color: rgba(6, 41, 88, 0.5);
}
.parallax {
  width: 100%;
  height: 700px;
  background-attachment: fixed;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  background-position-y: 0%;
  position: relative;
}

@media only screen and (max-width: 1024px) {
  .team {
    background-position-x: 0%;
    background-position-y: 5%;
    background-size: 100%;
    background-attachment: scroll;
    height: 300px;
  }
}

.image-row {
  width: 100%;
  height: auto;
  /* max-height: 15rem; */
}

.home {
  background-image: url(/static/media/background.2703b7fe.jpg);
}

.team {
  background-image: url(/static/media/group.94e17a53.jpg);
}

.design {
  background-image: url(/static/media/design.6ee84a17.jpg);
}

.main-quote {
  background-color: white;
  position: absolute;
  z-index: 10;
}

#welcome,
#design,
#team {
  color: white;
  position: absolute;
  z-index: 10;
}

#design {
  color: white;
}

.left-quote {
  top: 10%;
  left: 5%;
  max-width: 20rem;
  max-height: 20rem;
  text-overflow: none;
}

.right-quote {
  top: 10%;
  right: 5%;
  width: 30rem;
}

@media (max-width: 750px) {
  .right-quote {
    right: 0;
    top: 35%;
    width: 100%;
  }

  #welcome,
  #design {
    color: black;
    font-size: 3rem;
  }

  .bottom-quote {
    left: 0;
    top: 35%;
  }
}

.bottom-quote {
  bottom: 25%;
  left: 15%;
}

.profile {
  width: 100%;
}

.card-image-top {
  width: 100%;
  height: auto;
}

.card-image-left {
  height: 100%;
  width: auto;
}

.footer {
  background-color: rgb(5, 27, 56);
  color: bisque;
  padding: 2.5rem 4.5rem;
  margin: 0;
}

.footer a {
  color: bisque;
  transition: 0.1s color ease-in-out;
  text-decoration: none;
}

.footer a:hover {
  color: lightslategrey;
}

.big-font {
  font-size: 400%;
  font-weight: 700;
  line-height: 75%;
}

@media (max-width: 450px) {
  .big-font {
    font-size: 150%;
  }
}

.des {
  border: none;
}

.www-des {
  font-size: 1.25rem;
}

@media (max-width: 450px) {
  .www-des {
    font-size: 0.75rem;
    padding: 0;
  }
}

.App {
  text-align: center;
}

/* gallery css */

.year-section {
  /* width: 100%; */
  column-count: 3;
  column-width: 33%;
  margin-bottom: 3rem;
}

@media (max-width: 450px) {
  .year-section {
    column-count: 1;
    column-width: 100%;
    margin: 0;
    padding: 0;
  }
}

.pics img {
  width: 100%;
  height: auto;
}

.gallery {
  width: 100%;
  height: 100%;
  padding: 3rem;
}

.gallery h4 {
  width: 100%;
  padding: 1.5rem 0;
  font-weight: 700;
  font-size: 30px;
  background-color: #343a40;
}

.body-content {
  margin: 2.5rem auto;
}

.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
  pointer-events: none;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}


/* Blog Page Styling */

/* Header styling  */
.blog-header {
  text-align: center;
}

.blog-header h2 {
  color: #0080ff;
  font-size: 2rem;
}

.blog-header h1 {
  font-size: 3rem;
  color: rgb(5, 27, 56);
  margin-bottom: 1rem;
}

.blog-header h1 span {
  color: #b0c4de;
}

.blog-header p {
  color: #a9a9a9;
  font-weight: 500;
}

/* Blog Page: Search Bar Styling */

.searchBar-wrap {
  background-color: #f0f0f0;
  width: -moz-fit-content;
  width: fit-content;
  margin: 2.5rem auto 4rem auto;
  padding: 0.5rem;
  border-radius: 5px;
}

.searchBar-wrap form {
  display: flex;
  align-items: center;
}

.searchBar-wrap input {
  background-color: #f0f0f0;
  outline: none;
  border: none;
}

.searchBar-wrap span {
  padding-right: 0.5rem;
  cursor: pointer;
}

.searchBar-wrap button {
  outline: none;
  border: none;
  padding: 0.3rem 1rem;
  border-radius: 5px;
  background-color: rgb(5, 27, 56);
  color:bisque;
}

/* Blog Page: Blog List Styling */
.blogList-wrap {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 3rem;
  margin: 20px 5%;
  margin-bottom: 100px;
}

@media (max-width: 768px) {
  .blogList-wrap {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (max-width: 600px) {
  .blogList-wrap {
    grid-template-columns: repeat(1, 1fr);
  }
}

/* Blog Page: Blog Item Styling */
.blogItem-wrap {
  display: flex;
  flex-direction: column;
  border-radius: 20px;
  padding: 10px;
}
.blogItem-wrap:hover{
  background-color: rgba(14, 82, 114, 0.07);
}

.blogItem-cover {
  width: 100%;
  height: 250px;
  object-fit: cover;
  border-radius: 20px;
  margin-bottom: 0.5rem;
}

.blogItem-wrap h3 {
  margin: 0.5rem 0 1rem 0;
  flex: 1 1;
}

.blogItem-desc {
  position: relative;
  max-height: 50px;
  overflow: hidden;
  padding-right: 0.6rem;
  font-size: 0.8rem;
  color: #a9a9a9;
}

.blogItem-desc::before {
  position: absolute;
  content: '...';
  bottom: 0;
  right: 0;
}

.blogItem-wrap footer {
  display: flex;
  align-items: center;
  margin-top: 1rem;
  justify-content: space-between;
}

.blogItem-link {
  text-decoration: none;
  color: inherit;
}

.blogItem-author {
  display: flex;
  align-items: center;
}

.blogItem-author img {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  object-fit: cover;
  margin-right: 0.5rem;
}

.blogItem-author p {
  font-size: 0.6rem;
  color: #a9a9a9;
  font-weight: 600;
}

/* Blog Page: Chip styling */
.chip {
  font-size: 0.7rem;
  background: linear-gradient(to right, #6190e8, #a7bfe8);
  color: #fff;
  padding: 0.3rem 0.5rem;
  border-radius: 5px;
  width: -moz-fit-content;
  width: fit-content;
  text-transform: capitalize;
}

/* Blog Page: Emptylist styling */
.emptyList-wrap {
  display: flex;
  justify-content: center;
}

.emptyList-wrap img {
  max-width: 250px;
  width: 100%;
}

/* Blog Page Styling */
.blog-wrap {
  max-width: 700px;
  margin: 0 auto;
  margin-bottom: 100px;
}

.blog-goBack {
  text-decoration: none;
  font-size: 0.8rem;
  color: #a9a9a9;
  font-weight: 500;
  margin-bottom: 2rem;
  display: block;
}

.blog-wrap header {
  text-align: center;
}

.blog-date {
  font-size: 0.8rem;
  color: #a9a9a9;
  font-weight: 500;
}

.blog-wrap img {
  width: 100%;
}

.blog-subCategory {
  display: flex;
  justify-content: center;
}

.blog-subCategory>div {
  margin: 1rem;
}

.blog-body {
  padding: 1rem;
}

/* Author Socials in Blog Page */

.sticky-container {
  padding: 0px;
  margin: 0px;
  position: fixed;
  right: -130px;
  top: 230px;
  width: 210px;
  z-index: 1100;
}

.sticky li {
  list-style-type: none;
  background-color: rgba(255, 255, 255, 0);
  color: #efefef;
  height: 43px;
  padding: 0px;
  margin: 0px 0px 1px 0px;
  transition: all 0.25s ease-in-out;
  cursor: pointer;
}

.sticky li:hover {
  margin-left: -15px;
}

.sticky li img {
  float: left;
  margin: 5px 4px;
  margin-right: 5px;
}

/* .sticky li p a {
  text-decoration: none;
  color: #2C3539;
} */

.sticky li p a:hover {
  text-decoration: underline;
}
.sponsorComponentWrapper{
  width: 100%;
}

.partners{
  text-align: center;
  padding-top: 1em;
  padding-bottom: 3em;
}

.sponsorsList{
  display: flex;
  justify-content:center;
  align-items: center;
  flex-wrap: wrap;
  width: 100%;
}

.sponsorImageButton{
  background: none;
  color: inherit;
  border: none;
  padding: 0;
  font: inherit;
  cursor: pointer;
  outline: none;
  margin: 30px;
  aspect-ratio: 4/3;
}

.sponsorImageButton:focus {
  outline: 0 !important;
}

.sponsorImage{
  transition: transform .2s;
}

.sponsorImage:hover{
  transform: scale(1.2)
}

/* DIAMOND */
.diamondSponsors{
  background: linear-gradient(180deg,#B9F2FF,rgba(247,181,49,0));
  width: 80vw;
}

.diamondSponsors h3{
  color: #43b1ca;
  margin-bottom: 1.125em;
  filter: drop-shadow(0 4px 4px #B9F2FF);
}

.diamondSponsorImage{
  object-fit: contain;
  width: 600px;
  height: 56.25%;
}

/* GOLD */
.goldSponsors{
  background: linear-gradient(180deg,rgba(247,181,49,.375),rgba(247,181,49,0));
  width: 80vw;
}

.goldSponsors h3{
  color: #e58123;
  margin-bottom: 1.125em;
  filter: drop-shadow(0 4px 4px rgba(247,181,49,.5));
}

.goldSponsorImage{
  object-fit: contain;
  width: 340px;
  height: 56.25%;
}

/* SILVER */
.silverSponsors{
  background: linear-gradient(180deg,rgba(91,91,92,.25),rgba(91,91,92,0));
  width: 80vw;
}

.silverSponsorImage{
  object-fit: contain;
  width: 200px;
  height: 56.25%;
}

.silverSponsors h3 {
  color: #5b5b5c;
  filter: drop-shadow(0 4px 4px rgba(0,0,0,.25));
  margin-bottom: 1.125em;
}

/* BRONZE */
.bronzeSponsors{
  background: linear-gradient(180deg,rgba(205,127,50,.375),rgba(247,181,49,0));
  width: 80vw;
}

.bronzeSponsorImage{
  object-fit: contain;
  width: 120px;
  height: 56.25%;
}

/* COORPORATE */

.partnerSponsors{
  background: linear-gradient(180deg,hsla(0,0%,84.7%,.375),rgba(247,181,49,0));
  width: 80vw;
}

.partnerSponsorImage{
  object-fit: contain;
  width: 110px;
  height: 56.25%;
}
.viewer-center {
  align-items: center;
  text-align: center;
  margin: auto;
}
